html,
body,
#root {
    /*in next the target div is __next not root*/
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #000 !important;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
}

@font-face {
    font-family: 'SingleFighter';
    src: local('SingleFighter'),
        url(./assets/single-fighter.otf) format('opentype');
}

a:visited {
    color: white;
}

a {
    color: white;
    text-decoration: none;
}

.bold-small {
    font-weight: 700;
    font-size: 18px;
    color: #00ff79;
}
.bold {
    font-weight: 700;
    color: #00ff79;
}

.boldish {
    font-weight: 700;
}

.title {
    font-family: 'SingleFighter', sans-serif !important;
}

.underline {
    text-decoration: underline;
}

.fade-in {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.padding8 {
    padding: 8px;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
